import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';

export const useFeedback = () => {
  const openFeedbackPopup = () => {
    const { open, close } = useModal({
      modalId: 'feedback',
      component: defineAsyncComponent(
        () => import('@/components/popups/Feedback.vue'),
      ),
      attrs: {
        closeModal: () => {
          close();
        },
      },
    });

    open();
  };

  return {
    openFeedbackPopup,
  };
};
