<script setup>
import { useNuxtApp } from '#imports';
import { useUserStore } from '@/stores/user';
import { useCookie } from 'nuxt/app';

import UiButton from '@/components/ui/button/UiButton.vue';

const { $tp } = useNuxtApp();
const userStore = useUserStore();

const emit = defineEmits(['selectCookie']);

function selectCookie(value) {
  const adultCookie = useCookie('cookie');

  adultCookie.value = Boolean(value);
  userStore.setCookieState(value);

  emit('selectCookie');
}
</script>
<template>
  <div class="cookie-section">
    <div class="cookie-section__info">
      <p class="cookie-section__font cookie-section__font--info">
        {{
          $tp('Мы используем cookies для аналитики и правильной работы сайта')
        }}
      </p>
    </div>

    <div class="cookie-section__action">
      <UiButton
        theme="white"
        class="cookie-section__button"
        text="Принять"
        @click="selectCookie(true)"
      />

      <UiButton
        theme="second-white"
        class="cookie-section__button"
        text="Отклонить"
        @click="selectCookie(false)"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.cookie-section {
  display: flex;
  flex-direction: column;
  gap: em(20);
  width: 100%;
  max-width: em(416);
  padding: em(36) em(48) em(24);
  background-color: $color-grey-115;
  backdrop-filter: blur(5px);

  @include media-breakpoint-down(sm) {
    max-width: em(375);
    padding: em(36) em(30) em(24);
    backdrop-filter: blur(30px);
    gap: em(32);
  }

  &__font {
    &--info {
      @include subtitle-s;

      color: $color-white;

      @include media-breakpoint-down(sm) {
        color: $color-black-100;
      }
    }
  }

  &__info {
    text-align: center;
  }

  &__action {
    display: flex;
    gap: em(16);
  }

  &__button {
    width: calc(50% - #{em(8)});

    @include media-breakpoint-down(sm) {
      color: $color-black-100;
      border-color: $color-black-100;
    }
  }
}
</style>
