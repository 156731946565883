<template>
  <div class="resize">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
$design-width: 1920;
$design-height: 960;
$default-size: 16;
$minWidth: 1400px;
$minHeight: 600px;

.resize {
  font-size: min(
    calc($default-size * max($minWidth, 100vw) / $design-width),
    calc($default-size * max($minHeight, 100vh) / $design-height)
  );

  @include media-breakpoint-down(lg) {
    font-size: em($default-size);
  }
}
</style>
